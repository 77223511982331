<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <form class="mb-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-6">
          <h1 class="text-3xl mb-5">Validação Whatsapp</h1>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
            <router-link
              :to="`/${route}/import`"
              type="button"
              :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
              class="transition duration-200 mb-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
            >
              <span class="inline-block mr-2">{{ $t("txt-import") }}</span>
            </router-link>
          </div>
        </div>
        <div class="col-span-12">
          <label>Download Resultados</label>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-2">
              <label for="de" class="mt-2 block text-sm font-medium">A partir de</label>
              <input v-model="download.de" type="date" name="de" id="de" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 md:col-span-2">
              <label for="ate" class="mt-2 block text-sm font-medium">Até</label>
              <input v-model="download.ate" type="date" name="ate" id="ate" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
              <label for="dataDisparo" class="mt-2 block text-sm font-medium text-gray-700"> Centro de custo</label>
              <v-select v-model="centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
            </div>
            <div class="col-span-12 md:col-span-2 self-end mb-1">
              <button
                @click="list()"
                type="button"
                class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 px-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campanha</th>
                  <th v-if="$store.state.user.lzv2.centrocusto" scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Centro de Custo</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">DATA UPLOAD</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">DATA FINALIZADO</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>

                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>

                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tem zap</th>

                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Não tem zap</th>

                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resultado</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="campaign in campaigns" :key="campaign._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <a
                      v-if="campaign.arquivoUpload && campaign.arquivoUpload.filename"
                      :href="`${apilz}/v1/campanhasLZV2/download/arquivo?filename=${campaign.arquivoUpload.filename}&originalname=${campaign.arquivoUpload.originalname}&token=${$store.state.token}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span v-if="campaign.arquivoUpload && campaign.arquivoUpload.originalname.indexOf('arquivo-modelo-lembrazap') >= 0">{{ campaign.nome }}</span>
                      <span v-if="campaign.arquivoUpload && campaign.arquivoUpload.originalname.indexOf('arquivo-modelo-lembrazap') < 0">{{ campaign.arquivoUpload.originalname }}</span>
                      <small v-if="!campaign.arquivoUpload || !campaign.arquivoUpload.originalname">{{ campaign.nome }}</small>
                    </a>
                    <span v-else>{{ campaign.nome }}</span>
                  </td>
                  <td v-if="$store.state.user.lzv2.centrocusto" class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ campaign.centrocusto && campaign.centrocusto.nome ? campaign.centrocusto.nome : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ moment(campaign.dataUpload).format("DD/MM/YYYY HH:mm") }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ campaign.dataFinalizado ? moment(campaign.dataFinalizado).format("DD/MM/YYYY HH:mm") : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ moment(campaign.dataHoraDisparo).format("DD/MM/YYYY") }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ campaign.total }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ campaign.totalEnviado }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ campaign.totalFalhas }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    {{ returnStatus(campaign) }}
                  </td>
                  <td class="whitespace-nowrap flex flex-row">
                    <router-link
                      :to="`/${route}/detalhes/${campaign._id}`"
                      type="button"
                      :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
                      class="mt-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 rounded-md text-sm shadow-sm hover:shadow-md w-28 font-semibold text-center"
                    >
                      <span class="inline-block">Detalhes</span>
                    </router-link>

                    <button
                      :style="{ 'background-color': $store.state.colorPrincipal, color: invertColor($store.state.colorPrincipal, true) }"
                      class="mt-2 ml-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 rounded-md text-sm shadow-sm hover:shadow-md w-28 font-semibold text-center"
                      @click="downloadResultado(campaign._id)"
                    >
                      Download
                    </button>
                    <a
                      v-if="campaign.total <= 0"
                      class="mt-2 ml-2 bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 rounded-md text-sm shadow-sm hover:shadow-md w-28 font-semibold text-center cursor-pointer"
                      target="blank"
                      @click="excluir(campaign._id)"
                      >Excluir
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="fixed z-50 top-80 left-0 w-full text-center text-lg text-zinc-600 font-bold">
      <p class="text-zinc-200">Aguarde o fim do download!</p>
      <span class="text-green-600">{{ progresso }}</span> / 100%
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      breadcrumb: [{ url: "/temzap", value: "Validação Whatsapp" }],
      route: "temzap",
      apilz: window.location.hostname === "uno.trestto.com.br" ? "https://uno.trestto.com.br/api" : process.env.VUE_APP_API,
      moment,
      campaigns: [],
      download: {
        de: moment().startOf("M").format("YYYY-MM-DD"),
        ate: moment().endOf("M").format("YYYY-MM-DD")
      },
      centroscusto: [],
      centrocusto: null,
      selectedCampaigns: [],
      role: "user",
      page: 1,
      perPage: 25,
      total: 0,
      isLoading: false,
      progresso: 0
    };
  },
  methods: {
    async downloadResultado(campaign) {
      const response = await this.$http.get(`${this.apilz}/v1/campanhasLZV2/download-resultado/${campaign}`);

      if (response.data.success) {
        const loader = await this.$loading.show({
          container: null,
          canCancel: true
        });

        await this.sockets.subscribe(response.data.socket_campanha, (data) => {
          this.isLoading = true;
          this.progresso = data.progress;
        });

        await this.sockets.subscribe(response.data.socket_campanha_ready, (data) => {
          this.isLoading = false;
          loader.hide();
          window.location.href = `${this.apilz}/upload?mimetype=text/csv&filename=${data.file}&folder=relatorios&original=${data.file}`;
        });

        console.log(response.data);
      }
    },
    async excluir(id) {
      const certeza = await this.$dialog.confirm("Excluir essa campanha de validação?", "", "question");

      if (certeza.isOk) {
        await this.$http.delete(`/v1/campanhasLZV2/delete/${id}`);
        this.$vToastify.success("Campanha excluída com sucesso!");
        this.list();
      }
    },
    async setPage(page) {
      this.page = page;
      window.scrollTo(0, 0);

      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const campaignsReq = await this.$http.post("/v1/campanhasLZV2/list", {
        validacao: true,
        centrocusto: this.centrocusto,
        de: this.download.de,
        ate: this.download.ate,
        skip: skip,
        limit: limit
      });

      this.campaigns = campaignsReq.data.data;
      this.total = campaignsReq.data.total;
    },
    async list() {
      this.setPage(1);
    },
    async removeSeleted() {
      const resp = await this.$http.post("/v1/campanhasLZV2/deleteall", {
        campanhas: this.selectedCampaigns
      });
      if (!resp.data.success) {
        this.$vToastify.error(resp.data.err);
        return;
      }
      this.$vToastify.success("Campanhas removidas");
      this.list();
    },

    returnStatus(data) {
      const today = moment();

      if (data.totalEnviado === 0 && data.totalFalhas === 0 && moment(data.dataHoraDisparo) < today) {
        return "Preparando";
      } else if (data.ready && data.totalEnviado === 0 && data.totalFalhas === 0 && moment(data.dataHoraDisparo) > today) {
        return "Aguardando";
      } else if ((data.totalEnviado > 0 || data.totalFalhas > 0) && data.finalizado && moment(data.dataHoraDisparo) < today) {
        return "Concluída";
      } else if (data.totalEnviado > 0 || data.totalFalhas > 0) {
        return "Em andamento";
      }
    }
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }

    this.list();
  }
};
</script>
